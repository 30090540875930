
export default {
    props : [
        'data',
        'crop',
        'size',
        'preload',
        'fitIn',
        'smart',
        'quality'
    ],
    data() {
        return {
            placeholderImg : 'https://a.storyblok.com/f/159926/3x3/01b670be43/placeholder.jpg',
            single : null,
            double : null,
            elW: 0,
            elH: 0,
            sbParams : { 
                format: this.askForWebp,
                modifiers: this.modifiers,
                fit: this.fitIn ? 'in' : null,
                quality : this.quality ? this.quality : 80
            }      
        }
    },
    computed:{
        assetFormat(){
            var re = /(?:\.([^.]+))?$/;
            return re.exec(this.data.filename)[1];
        },
        isWebpSupported(){
            return this.$store.state.layout.isWebpSupported;
        },
        isMobile(){
            return this.$store.state.layout.isMobile;
        },
        askForWebp(){
            return this.isWebpSupported || this.isMobile ? 'webp' : null
        },
        wW () {
            return this.$store.state.scroll.wW;
        },
        modifiers(){
            var obj = {};
            if(this.smart || this.crop) obj.smart = true;
            return obj
        }
    },
    watch:{
        wW() {
            this.calcDimensions();
        },
        data:{
            deep:true,
            handler(){
                this.calcDimensions();
            }
        }
    },
    mounted(){
        this.calcDimensions();
    },
    methods: {
        calcDimensions(){
            if(this.size){
                this.elW = this.size.w ? parseInt(this.size.w) : null;
                this.elH = this.size.h ? parseInt(this.size.h) : null;
            }else{
                var size = {w: this.$el.clientWidth, h: this.$el.clientHeight}
                this.elW = size.w ? parseInt(size.w) : null;
                this.elH = size.h ? parseInt(size.h) : null;
            }
        }
    },
}
